<template>
  <div class="FakeDataRibbonWrapper is-position-relative">
    <slot />
    <div
      v-if="shouldBeVisible"
      :class="`ribbon--${size}`"
      class="ribbon ribbon-top-left"
    >
      <span class="has-background-info has-text-weight-semibold">
        <v-icon type="star" />
        {{ $t(ribbonText) }}
      </span>
    </div>
  </div>
</template>

<script>
/**
 * Generates a Ribbon on the top-left corner stating its content has fake data
 * @module FakeDataRibbon
 */
export default {
  name: 'FakeDataRibbon',
  props: {
    isVisible: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'medium',
      validator: s => ['small', 'medium', 'large'].includes(s)
    },
    ribbonText: {
      type: String,
      default: 'components.fake_data_ribbon.feature_preview'
    }
  },
  computed: {
    /**
     * If the component has the isVisible prop provided,
     * it has precedence over the requestDemoData vuex getter
     * @return {*}
     */
    shouldBeVisible () {
      return this.$options.propsData.hasOwnProperty('isVisible')
        ? this.isVisible
        : (this.$store.getters.requestDemoData || false)
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.ribbon {
  width: 250px;
  height: 200px;
  overflow: hidden;
  position: absolute;
  transform-origin: top left;
  pointer-events: none;
  z-index: 13;

  span {
    position: absolute;
    display: block;
    width: 345px;
    padding: $padding-small*1.4 0;
    text-shadow: 0 1px 1px darken($info, 10%);
    text-transform: uppercase;
    text-align: center;
    top: 64px;
    right: -21px;
    transform: rotate(-38deg);
  }

  /* top left*/
  &.ribbon-top-left {
    top: 0;
    left: 0;

    &::before, &::after {
      border-top-color: transparent;
      border-left-color: transparent;
    }

    &::before {
      top: 0;
      right: 0;
    }

    &::after {
      bottom: 0;
      left: 0;
    }
  }

  &--small {
    transform: scale(0.8);
  }

  &--large {
    transform: scale(1.2)
  }
}
</style>
